import StatusProgressBar from "@/components/atom/progress/status/StatusProgressBar";
import { WorkText } from "@/components/atom/text/work/WorkText";
import { WorkTitle } from "@/components/atom/title/work/WorkTitle";
import { QuantityBox } from "@/components/molecule/quantityBox/QuantityBox";
import { COMMON_TEXT } from "@/constants/text";
import { useWorkDataStore } from "@/store/work.store";
import { Flex, Text } from "@mantine/core";

const WorkProgress = Object.assign(
  {},
  {
    Title: WorkTitle,
    Text: WorkText,
    Progress: StatusProgressBar,
  }
);

export const WorkProgressInform = () => {
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { work } = workData;

  const isPacking = work?.equipment?.equipmentType === "PACKING";

  return (
    <Flex gap={"xs"} direction={"column"}>
      <Flex justify={"space-between"}>
        <Flex>
          <Text c={"white"}>{COMMON_TEXT.TITLE.TARGET_QUANTITY} :</Text>
          <Text c={"white"} ml={"xs"}>
            {work?.targetQuantity}
            {work?.item?.unitText}
          </Text>
        </Flex>
        <Flex>
          <Text c={"white"}>{COMMON_TEXT.TITLE.PROGRESS}</Text>
          <Text c={"white"} ml={"xs"}>
            {Number(work?.summary?.percent).toFixed(2)}%
          </Text>
        </Flex>
      </Flex>
      <WorkProgress.Progress value={Number(work?.summary?.percent)} />
      <Flex gap={"xs"} w={"100%"} justify={"space-between"}>
        {isPacking ? (
          <>
            <QuantityBox
              size="xl"
              color="violet.4"
              value={Number(work?.summary?.end).toString()}
              title={COMMON_TEXT.TITLE.TOTAL_COUNT}
            />
            <QuantityBox
              size="xl"
              color="violet.4"
              value={(
                Number(work?.summary?.end) / Number(work?.kgPerCount)
              ).toString()}
              title={COMMON_TEXT.TITLE.PACKING_COUNT}
            />
            <QuantityBox
              size="xl"
              color="teal.4"
              value={work?.kgPerCount}
              title={COMMON_TEXT.TITLE.KG_PER_COUNT}
            />
          </>
        ) : (
          <>
            <QuantityBox
              size="xl"
              color="violet.4"
              value={work?.summary?.end}
              title={COMMON_TEXT.TITLE.PRODUCTION_QUANTITY}
            />
            <QuantityBox
              size="xl"
              color="teal.4"
              value={work?.summary?.totalQuantity}
              title={COMMON_TEXT.TITLE.TOTAL_QUANTITY}
            />

            <QuantityBox
              size="xl"
              color="red.4"
              value={work?.summary?.defectTotal}
              title={COMMON_TEXT.TITLE.DEFECT_QUANTITY}
            />
            <QuantityBox
              size="xl"
              color="blue.4"
              value={work?.summary?.todoQuantity}
              title={COMMON_TEXT.TITLE.TODO_QUANTITY}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
};

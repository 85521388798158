import { BaseButton } from "@/components/atom/button/BaseButton";
import { MantineDataTable } from "@/components/molecule/mantineDataTable/MantineDataTable";
import { OutgoingAllForm } from "@/components/organism/form/OutgoingAllForm";
import { TransferAllForm } from "@/components/organism/form/TransferAllForm";
import PageTemplate from "@/components/template/page/PageTemplate";
import { itemTypeColor, itemTypeObj } from "@/constants/items";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";
import { useInventoryFetch } from "@/fetch/page/useInventoryFetch";
import { usePaginationStore } from "@/store/pagenation.store";
import timeUtil from "@/utils/timeUtil";
import { setToLocaleString } from "@/utils/unitMark";
import { Badge, Flex, Pagination } from "@mantine/core";
import { IconArrowBarToRight, IconMinus } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useEffect } from "react";

const Inventories = Object.assign(
  {},
  {
    Template: PageTemplate,
    Table: MantineDataTable,
    Button: BaseButton,
    Pagination: Pagination,
  }
);

export const InventoriesPage = () => {
  const { openModal } = useModal();
  const { data, Fetcher: InventoryFetcher } = useInventoryFetch();
  const InventoriesData = data?.data.rows;

  const convertedInventoriesData = InventoriesData?.map((inventory) => {
    return {
      ...inventory,
      grade: inventory.itemWithGrade?.gradeName ?? "-",
      color: inventory.itemWithGrade?.itemCategoryName ?? "-",
      shape: inventory.itemAbstractItemCode,
      lotName: inventory.lot?.name ?? "-",
      lotCode: inventory.lot?.code ?? "-",
      lotExpiration: inventory.lot?.expiration
        ? dayjs(inventory.lot?.expiration).format("YYYY-MM-DD")
        : "-",
      closedAt: timeUtil(inventory.closedAt),
      increaseQuantity: `${setToLocaleString(inventory.increaseQuantity)}${
        inventory.unitText
      }`,
      decreaseQuantity: `${setToLocaleString(inventory.decreaseQuantity)}${
        inventory.unitText
      }`,
      deltaQuantity: `${setToLocaleString(inventory.deltaQuantity)}${
        inventory.unitText
      }`,
      quantity: `${setToLocaleString(inventory.quantity)}${inventory.unitText}`,
    };
  });

  const { currentPage, setPage } = usePaginationStore((state) => ({
    currentPage: state.currentPage,
    setPage: state.setPage,
  }));
  const totalPages = data?.data.totalPages;

  const columns = () => [
    {
      accessorKey: "ItemCategory",
      header: COMMON_TEXT.COLUMN.ITEM_CATEGORY,
      Cell: ({ cell }: any) => {
        const { itemType } = cell.row.original;
        return (
          <Badge
            color={itemTypeColor[itemType as keyof typeof itemTypeColor]}
            p="xs"
          >
            {itemTypeObj[itemType as keyof typeof itemTypeColor]}
          </Badge>
        );
      },
    },
    {
      accessorKey: "itemName",
      header: COMMON_TEXT.COLUMN.ITEM_NAME,
    },
    {
      accessorKey: "grade",
      header: COMMON_TEXT.COLUMN.GRADE,
    },
    {
      accessorKey: "color",
      header: COMMON_TEXT.COLUMN.COLOR,
    },
    {
      accessorKey: "shape",
      header: COMMON_TEXT.COLUMN.SHAPE,
    },
    {
      accessorKey: "lotName",
      header: COMMON_TEXT.COLUMN.LOT_NAME,
    },
    {
      accessorKey: "locationName",
      header: COMMON_TEXT.COLUMN.LOCATION,
    },
    {
      accessorKey: "increaseQuantity",
      header: COMMON_TEXT.COLUMN.INCREASE_QUANTITY,
    },
    {
      accessorKey: "decreaseQuantity",
      header: COMMON_TEXT.COLUMN.DECREASE_QUANTITY,
    },
    {
      accessorKey: "deltaQuantity",
      header: COMMON_TEXT.COLUMN.DELTA_QUANTITY,
    },
    {
      accessorKey: "quantity",
      header: COMMON_TEXT.COLUMN.FINAL_QUANTITY,
    },
  ];

  useEffect(() => {
    setPage(1);
  }, []);

  return (
    <InventoryFetcher>
      <Inventories.Template>
        <Flex justify={"flex-end"} gap={"md"} mb={"md"}>
          <Inventories.Button
            bg={"indigo"}
            rightIcon={<IconMinus />}
            onClick={() => {
              openModal(
                <OutgoingAllForm />,
                "",
                COMMON_TEXT.FORM.OUTGOING_ALL_TITLE
              );
            }}
          >
            {COMMON_TEXT.BUTTON.OUTGOING_ALL}
          </Inventories.Button>
          <Inventories.Button
            bg={"indigo"}
            rightIcon={<IconArrowBarToRight />}
            onClick={() => {
              openModal(
                <TransferAllForm />,
                "",
                COMMON_TEXT.FORM.TRANSFER_ALL_TITLE
              );
            }}
          >
            {COMMON_TEXT.BUTTON.TRANSFER_ALL}
          </Inventories.Button>
        </Flex>
        <Inventories.Table
          columns={columns()}
          data={convertedInventoriesData ?? []}
        />
        <Flex mt={20} justify={"center"}>
          <Inventories.Pagination
            total={totalPages || 0}
            value={currentPage}
            onChange={(page) => setPage(page)}
          />
        </Flex>
      </Inventories.Template>
    </InventoryFetcher>
  );
};

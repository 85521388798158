import { AppShellAside } from "@/components/organism/appShell/appShellAside/AppShellAside";
import { AppShellHeader } from "@/components/organism/appShell/appShellHeader/AppShellHeader";
import { WorkPage as OriginalWorkPage } from "@/components/page/original/workPage";
import { WorksPage as OriginalWorksPage } from "@/components/page/original/worksPage";
import { InspectRecordsPage } from "@/components/page/refactor/inspectRecordsPage";
import { InventoriesPage } from "@/components/page/refactor/inventoriesPage";
import { LoginPage } from "@/components/page/refactor/loginPage";
import { WorkPage as RefactorWorkPage } from "@/components/page/refactor/workPage";
import { WorksPage as RefactorWorksPage } from "@/components/page/refactor/worksPage";
import { customFunctions } from "@/config/customFunctions";

import { COMMON_TEXT } from "@/constants/text";
import { useLocalStorage } from "@/context/LocalStorageProvider";
import { AppShell } from "@mantine/core";
import { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

export default function App() {
  const refactorDesign = customFunctions.ADD_REFACTOR_DESIGN;

  const routes = [
    {
      path: "/works",
      name: COMMON_TEXT.PAGE.WORKS,
      element: refactorDesign ? <RefactorWorksPage /> : <OriginalWorksPage />,
    },
    {
      path: "/work/:workId",
      name: COMMON_TEXT.PAGE.POP,
      element: refactorDesign ? <RefactorWorkPage /> : <OriginalWorkPage />,
    },
    {
      path: "/inventory",
      name: COMMON_TEXT.PAGE.INVENTORY,
      element: <InventoriesPage />,
    },
    {
      path: "/inspections",
      name: COMMON_TEXT.PAGE.INSPECT_LOG,
      element: <InspectRecordsPage />,
    },
  ];

  const { isAuthenticated } = useLocalStorage();
  const isUserAuthenticated = JSON.parse(isAuthenticated || "false");

  const [opened, setOpened] = useState(false);
  const [asideOpened, setAsideOpened] = useState(false);

  return isUserAuthenticated ? (
    <AppShell
      styles={{
        main: {
          paddingRight: asideOpened
            ? "1rem"
            : `calc(var(--mantine-aside-width, 0px) + 1rem)`,
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      header={
        <AppShellHeader
          opened={opened}
          asideOpened={asideOpened}
          setOpened={setOpened}
          setAsideOpened={setAsideOpened}
        />
      }
      aside={asideOpened ? <AppShellAside /> : <></>}
    >
      <Routes>
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Routes>
    </AppShell>
  ) : (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      {/* 인증되지 않은 사용자는 항상 로그인 페이지로 리다이렉트 */}
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
}

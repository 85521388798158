import { inventories } from "@/api/inventories/useInventoriesQuery";
import useFetcher from "@/hooks/useFetcher";
import { ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";

interface useInventoriesFetchProps {
  workData: ProductionPlansGet200ResponseRowsInnerWorksInner;
}

export const useInventoriesFetch = ({ workData }: useInventoriesFetchProps) => {
  const { queryKey, queryFn } = inventories.find({
    query: {
      $and: [
        {
          itemCode: workData?.routingData?.routingBoms?.map(
            (bom) => bom.itemCode
          ),
        },
        {
          locationCode:
            workData?.equipment?.toLocationCode ??
            workData?.currentRoutingOutsourceData?.toLocationCode ??
            workData?.locationSetting?.toLocation?.code ??
            workData?.routingData?.operation?.toLocationCode,
        },
        { quantity: { $ne: 0 } },
      ],
    },
    populate: ["item", "lot", "grade", "itemCategory", "itemWithGrade"],
  });

  const { data, Fetcher } = useFetcher(
    {
      queryKey: queryKey,
      queryFn: queryFn,
    },
    { enabled: !!workData }
  );

  return { data, Fetcher };
};

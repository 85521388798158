import { BaseButton } from "@/components/atom/button/BaseButton";
import { DecrementButton } from "@/components/atom/button/decrease/DecrementButton";
import { IncrementButton } from "@/components/atom/button/increase/IncrementButton";
import { FormNumberInput } from "@/components/atom/numberInput/form/FormNumberInput";
import { WorkTitle } from "@/components/atom/title/work/WorkTitle";
import { DefectAutoComplete } from "@/components/organism/autoComplete/defect/DefectAutoComplete";
import {
  DefectObject,
  DefectObjectProps,
} from "@/components/organism/work/original/WorkCurrentDefectInform";
import { customFunctions } from "@/config/customFunctions";
import { COMMON_TEXT } from "@/constants/text";
import { useLocalStorage } from "@/context/LocalStorageProvider";
import { usePerformanceHandler } from "@/hooks/handler/usePerformanceHandler";
import { useWorkDataStore } from "@/store/work.store";
import { plusBigNumber } from "@/utils/calculate";
import { customNotification } from "@/utils/notificationShow";
import { Flex } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconCircleNumber4, IconTrashOff } from "@tabler/icons-react";
import BigNumber from "bignumber.js";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const WorkDefect = Object.assign(
  {},
  {
    Title: WorkTitle,
    Button: BaseButton,
    AutoComplete: DefectAutoComplete,
    Input: FormNumberInput,
    IncrementButton: IncrementButton,
    DecrementButton: DecrementButton,
  }
);

export const WorkDefectActions = () => {
  const { tempWork, setTempWork } = useLocalStorage();
  const [defectCode, setDefectCode] = useState<string | null>("");

  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { work } = workData;
  const { handleOnDefect } = usePerformanceHandler();
  const { t } = useTranslation();

  const form = useForm({
    initialValues: {
      defects: {} as any,
    },
  });

  // 임시 불량 저장
  const handleTempDefectSave = (data: DefectObjectProps) => {
    setTempWork({
      ...tempWork,
      defects: {
        ...tempWork?.defects,
        [data.defectCode as string]: {
          ...tempWork?.defects?.[data.defectCode || ""],
          defectName: data?.defectName,
          defectQuantity: plusBigNumber(
            tempWork?.defects?.[data.defectCode || ""]?.defectQuantity ?? 0,
            data?.defectQuantity
          ),
        },
      },
    });
  };

  const handleDefectSave = (data: DefectObjectProps) => {
    const isDefectQuantityGreaterThanEnd = BigNumber(
      data.defectQuantity
    ).isGreaterThan(work?.summary?.end as string);
    const isDefectQuantityGreaterThanEndDetailMessage = t(
      "불량 수량이 생산수량보다 많습니다."
    );

    if (isDefectQuantityGreaterThanEnd) {
      return customNotification.error({
        message: isDefectQuantityGreaterThanEndDetailMessage,
      });
    }

    handleOnDefect({
      defectCode: data.defectCode as string,
      quantity: data.defectQuantity,
    });
  };

  const handleDefectReport = () => {
    customFunctions.ADD_TEMP_FUNCTION_ENABLED
      ? handleTempDefectSave({
          ...form.values.defects[defectCode as string],
          defectCode: defectCode as string,
        })
      : handleDefectSave({
          ...form.values.defects[defectCode as string],
          defectCode: defectCode as string,
        });
    setDefectCode(null);
  };

  const handleDefectAutoComplete = (defectCode: string | null) => {
    if (defectCode !== null) {
      form.setValues({
        defects: {
          ...form.values.defects,
          [defectCode as string]: {
            ...form.values.defects?.[defectCode || ""],
            defectName: (work?.summary?.defect as DefectObject)?.[
              defectCode as string
            ]?.defectName,
            defectQuantity: "0",
          },
        },
      });
    }
    setDefectCode(defectCode);
  };

  return (
    <>
      <Flex w={"100%"} justify={"space-between"}>
        <WorkDefect.Title c={"#FFFFFF"} order={5}>
          {COMMON_TEXT.TITLE.DEFECT_REPORT}
        </WorkDefect.Title>
        <WorkDefect.Button
          rightIcon={<IconTrashOff />}
          leftIcon={<IconCircleNumber4 size={20} />}
          onClick={handleDefectReport}
          color="red"
          size="sm"
        >
          {COMMON_TEXT.BUTTON.DEFECT_REPORT}
        </WorkDefect.Button>
      </Flex>
      <Flex gap={10} mt={"sm"}>
        <WorkDefect.AutoComplete
          defectOperationCode={work?.routingData?.operationCode}
          value={defectCode}
          onChange={handleDefectAutoComplete}
        />
        <WorkDefect.DecrementButton
          disabled={!defectCode}
          num={"5"}
          size="sm"
          {...form.getInputProps(`defects.${defectCode}.defectQuantity`)}
        />
        <WorkDefect.DecrementButton
          disabled={!defectCode}
          num={"1"}
          size="sm"
          {...form.getInputProps(`defects.${defectCode}.defectQuantity`)}
        />
        <WorkDefect.Input
          disabled={!defectCode}
          {...form.getInputProps(`defects.${defectCode}.defectQuantity`)}
          value={Number(
            form.values.defects?.[defectCode || ""]?.defectQuantity ?? 0
          )}
        />
        <WorkDefect.IncrementButton
          disabled={!defectCode}
          num={"1"}
          size="sm"
          {...form.getInputProps(`defects.${defectCode}.defectQuantity`)}
        />
        <WorkDefect.IncrementButton
          disabled={!defectCode}
          num={"5"}
          size="sm"
          {...form.getInputProps(`defects.${defectCode}.defectQuantity`)}
        />
      </Flex>
    </>
  );
};

import { HeaderActionButton } from "@/components/atom/button/headerAction/HeaderActionButton";
import { StatusButtonGroup } from "@/components/molecule/statusButtonGroup/StatusButtonGroup";
import { DefectRecycleForm } from "@/components/organism/form/DefectRecycleForm";
import { InputLogsForm } from "@/components/organism/form/InputLogsForm";
import { InspectForm } from "@/components/organism/form/InspectForm";
import { PalletLogsForm } from "@/components/organism/form/PalletLogsForm";
import { WorkLogsForm } from "@/components/organism/form/WorkLogsForm";
import { customFunctions } from "@/config/customFunctions";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";
import { useMaterialManagementHandler } from "@/hooks/handler/useMaterialManagementHandler";
import { useStatusHandler } from "@/hooks/handler/useStatusHandler";
import { useWorkDataStore } from "@/store/work.store";
import { customNotification } from "@/utils/notificationShow";
import { Flex } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
  IconCircleArrowUpLeft,
  IconCircleNumber1,
  IconCircleNumber2,
  IconCircleNumber5,
  IconPlayerPause,
  IconPlayerPlay,
  IconPower,
  IconRecycle,
} from "@tabler/icons-react";

export interface ButtonConfig {
  condition?: () => boolean;
  buttons: {
    key: string;
    color: string;
    icon?: JSX.Element;
    onClick: () => void;
    text: string;
    visible?: boolean;
  }[];
}

const Header = Object.assign(
  {},
  {
    ActionButton: HeaderActionButton,
    StatusButtonGroup: StatusButtonGroup,
  }
);

export const WorkHeader = () => {
  const { openModal } = useModal();
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { work } = workData;
  const isPacking = work?.equipment?.equipmentType === "PACKING";

  const { handleStart, handleEnd, handlePause, handleResume, handleCancelEnd } =
    useStatusHandler();

  const { handleInputRawMaterial, handleInputRawMaterialAll } =
    useMaterialManagementHandler();

  const logButtonConfigs: ButtonConfig[] = [
    {
      buttons: [
        {
          key: "inputAll",
          color: "teal.6",
          onClick: handleInputRawMaterial,
          icon: <IconCircleNumber2 size={20} />,
          text: COMMON_TEXT.BUTTON.RAW_MATERIAL_INPUT,
          visible: true,
        },
        {
          key: "inputAll",
          color: "teal.6",
          icon: <IconCircleNumber2 size={20} />,
          onClick: handleInputRawMaterialAll,
          text: COMMON_TEXT.BUTTON.RAW_MATERIAL_ALL_INPUT,
          visible: true,
        },
        {
          key: "log",
          color: "yellow.6",
          onClick: () =>
            openModal(<InputLogsForm />, "", COMMON_TEXT.FORM.INPUT_LOGS_TITLE),
          text: COMMON_TEXT.BUTTON.INPUT_LOGS,
          visible: true,
        },
        {
          key: "workLogs",
          color: "cyan.6",
          onClick: () =>
            openModal(<WorkLogsForm />, "", COMMON_TEXT.FORM.WORK_LOGS_TITLE),
          text: COMMON_TEXT.BUTTON.WORK_LOGS,
          visible: true,
        },
        {
          key: "inspection",
          color: "blue.6",
          onClick: () =>
            openModal(<InspectForm />, "", COMMON_TEXT.FORM.INSPECT_LOGS_TITLE),
          text: COMMON_TEXT.BUTTON.RECORD_INSPECT,
          visible: false,
        },
      ],
    },
  ];

  const statusButtonConfig: ButtonConfig[] = [
    {
      condition: () =>
        work?.externalCounterSetting !== "HANDWRITING" &&
        work?.trackingStatus === "WAITING",
      buttons: [
        {
          key: "start",
          color: "green.6",
          icon: (
            <>
              <IconPlayerPlay />
              <IconCircleNumber1 size={20} />
            </>
          ),
          onClick: () => handleStart(),
          text: COMMON_TEXT.STATUS.START,
        },
      ],
    },
    {
      condition: () => work?.trackingStatus === "WORKING",
      buttons: [
        {
          key: "pause",
          color: "orange.6",
          icon: (
            <div>
              <IconPlayerPause />
            </div>
          ),
          onClick: () => handlePause(),
          text: COMMON_TEXT.STATUS.PAUSED,
        },
        {
          key: "end",
          color: "red",
          icon: (
            <>
              <IconPower />
              <IconCircleNumber5 size={20} />
            </>
          ),
          onClick: () => handleEnd(),
          text: COMMON_TEXT.STATUS.END,
        },
      ],
    },
    {
      condition: () => work?.trackingStatus === "PAUSED",
      buttons: [
        {
          key: "resume",
          color: "green.6",
          icon: <IconPlayerPlay />,
          onClick: () => handleResume(), // ✅ 호출 명확히 처리
          text: COMMON_TEXT.STATUS.RESUME,
        },
      ],
    },
    {
      condition: () => work?.trackingStatus === "DONE",
      buttons: [
        {
          key: "cancelEnd",
          color: "blue.6",
          icon: <IconCircleArrowUpLeft />,
          onClick: () => handleCancelEnd(),
          text: COMMON_TEXT.STATUS.CANCEL_END,
        },
      ],
    },
  ];

  if (customFunctions.ADD_PALLET_LOGS) {
    logButtonConfigs[0].buttons.push({
      key: "palletLogs",
      color: "orange.6",
      onClick: () =>
        openModal(<PalletLogsForm />, "", COMMON_TEXT.FORM.PALLET_LOGS_TITLE),
      text: COMMON_TEXT.BUTTON.PALLET_LOGS,
      visible: false,
    });
  }

  if (customFunctions.ADD_RECYCLE_DEFECT) {
    logButtonConfigs[0].buttons.push({
      key: "recycleDefect",
      onClick: () => {
        if (!work?.defectLocationCode) {
          return customNotification.error({
            message: COMMON_TEXT.NOTIFICATION.NO_DEFECT_LOCATION,
          });
        }

        return openModal(
          <DefectRecycleForm />,
          "",
          COMMON_TEXT.FORM.RECYCLE_LOGS_TITLE
        );
      },
      color: "green.8",
      icon: <IconRecycle />,
      text: COMMON_TEXT.BUTTON.DEFECT_RECYCLE,
      visible: isPacking ? false : true,
    });
  }

  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  return isSmallScreen ? (
    <Flex
      gap={"xs"}
      justify={"space-between"}
      mb={"md"}
      direction={isSmallScreen ? "column" : "row"}
    >
      <Flex justify={isSmallScreen && "flex-end"}>
        <Header.StatusButtonGroup buttonConfigs={statusButtonConfig} />
      </Flex>
      <Flex gap={"xs"}>
        {logButtonConfigs.map((config) =>
          config.buttons.map((button) => (
            <Header.ActionButton
              display={button.visible ? "block" : "none"}
              key={button.key}
              onClick={button.onClick}
              color={button.color}
              leftIcon={button.icon}
            >
              {button.text}
            </Header.ActionButton>
          ))
        )}
      </Flex>
    </Flex>
  ) : (
    <Flex gap={"xs"} justify={"space-between"} mb={"md"}>
      <Flex gap={"xs"}>
        {logButtonConfigs.map((config) =>
          config.buttons.map((button) => (
            <Header.ActionButton
              display={button.visible ? "block" : "none"}
              key={button.key}
              onClick={button.onClick}
              color={button.color}
              leftIcon={button.icon}
            >
              {button.text}
            </Header.ActionButton>
          ))
        )}
      </Flex>
      <Flex>
        <Header.StatusButtonGroup buttonConfigs={statusButtonConfig} />
      </Flex>
    </Flex>
  );
};

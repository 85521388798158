import { FormNumberInput } from "@/components/atom/numberInput/form/FormNumberInput";
import { FormTextInput } from "@/components/atom/textInput/form/FormTextInput";
import { FormSubTitle } from "@/components/atom/title/form/FormSubTitle";
import { FormTitle } from "@/components/atom/title/form/FormTitle";
import { InventoriesAutoComplete } from "@/components/organism/autoComplete/inventory/inventories-autoComplete";
import { ItemAutoComplete } from "@/components/organism/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/components/organism/autoComplete/location/location-autoComplate";
import FormTemplate from "@/components/template/form/FormTemplate";
import { COMMON_TEXT } from "@/constants/text";
import { useDefectRecycleHandler } from "@/hooks/handler/useDefectRecycleHandler";
import { useWorkDataStore } from "@/store/work.store";
import { Divider } from "@mantine/core";
import { useForm } from "@mantine/form";
import { WmsTransformPostRequest } from "@sizlcorp/sizl-api-document/dist/models";

const DefectRecycle = Object.assign(
  {},
  {
    Template: FormTemplate,
    Title: FormTitle,
    SubTitle: FormSubTitle,
    TextInput: FormTextInput,
    NumberInput: FormNumberInput,
    ItemAutoComplete: ItemAutoComplete,
    LocationAutoComplete: LocationAutoComplete,
    InventoriesAutoComplete: InventoriesAutoComplete,
  }
);

export const DefectRecycleForm = () => {
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { work } = workData;

  const { onSubmit } = useDefectRecycleHandler();

  const form = useForm<WmsTransformPostRequest>({
    initialValues: {
      sourceItemCode: work?.item?.code as string,
      sourceLocationCode: work?.defectLocationCode as string,
      sourceLotId: 0,
      sourceQuantity: "",
      targetItemCode: "",
      targetLocationCode: "",
      targetLotId: 0,
      targetQuantity: "",
    },
    validate: {
      sourceLocationCode: (value) =>
        !value ? COMMON_TEXT.VALIDATE.LOCATION : undefined,
      sourceLotId: (value) =>
        !value ? COMMON_TEXT.VALIDATE.INVENTORY : undefined,
      sourceQuantity: (value) =>
        !value ? COMMON_TEXT.VALIDATE.QUANTITY : undefined,
      targetItemCode: (value) =>
        !value ? COMMON_TEXT.VALIDATE.ITEM : undefined,
      targetLocationCode: (value) =>
        !value ? COMMON_TEXT.VALIDATE.LOCATION : undefined,
      targetLotId: (value) =>
        !value ? COMMON_TEXT.VALIDATE.INVENTORY : undefined,
      targetQuantity: (value) =>
        !value ? COMMON_TEXT.VALIDATE.QUANTITY : undefined,
    },
  });

  const handleOnSubmit = () => {
    onSubmit({
      wmsTransformPostRequest: {
        ...form.values,
        sourceLotId: Number(form.values.sourceLotId),
        targetLotId: Number(form.values.targetLotId),
        targetQuantity: String(form.values.targetQuantity),
      },
    });
  };

  return (
    <DefectRecycle.Template onSubmit={handleOnSubmit} label="변환">
      <DefectRecycle.SubTitle fw={"bold"}>
        {COMMON_TEXT.FORM.RECYCLABLE_DEFECT_TITLE}
      </DefectRecycle.SubTitle>
      <DefectRecycle.LocationAutoComplete
        key="sourceLocationCode"
        disabled
        label={COMMON_TEXT.LABEL.DEFECT_LOCATION}
        query={{ $and: [{ isAvailable: false }] }}
        {...form.getInputProps("sourceLocationCode")}
      />
      <DefectRecycle.InventoriesAutoComplete
        key="sourceLotId"
        label={COMMON_TEXT.LABEL.INVENTORY}
        setSelectedOption={(data) => {
          form.setFieldValue("sourceQuantity", data?.quantity as string);
        }}
        {...form.getInputProps("sourceLotId")}
        query={{
          locationCode: form.values.sourceLocationCode,
        }}
        onChange={(e: any) => {
          // 이동 창고 삭제시 재고, 품목, 수량 초기화
          if (e === null) {
            form.setFieldValue("sourceQuantity", "");
            form.setFieldValue("sourceLotId", 0);
          } else {
            form.setFieldValue("sourceLotId", e);
          }
        }}
      />
      <DefectRecycle.NumberInput
        key="sourceQuantity"
        label={COMMON_TEXT.LABEL.QUANTITY}
        {...form.getInputProps("sourceQuantity")}
        value={Number(form.values.sourceQuantity)}
      />
      <Divider />
      <DefectRecycle.SubTitle fw={"bold"}>
        {COMMON_TEXT.FORM.RECYCLED_DEFECT_TITLE}
      </DefectRecycle.SubTitle>
      <DefectRecycle.ItemAutoComplete
        key="targetItemCode"
        label={COMMON_TEXT.LABEL.ITEM}
        {...form.getInputProps("targetItemCode")}
        onChange={(e: string | null) => {
          // 품목을 삭제할시 재고, 창고, 수량 초기화
          if (e === null) {
            form.setFieldValue("targetLocationCode", "");
            form.setFieldValue("targetLotId", 0);
            form.setFieldValue("targetQuantity", "");
            form.setFieldValue("targetItemCode", "");
          } else {
            form.setFieldValue("targetItemCode", e);
          }
        }}
      />
      <DefectRecycle.LocationAutoComplete
        key={`targetLocationCode + ${form.values.targetItemCode}`}
        label={COMMON_TEXT.LABEL.TARGET_LOCATION}
        disabled={!form.values.targetItemCode}
        {...form.getInputProps("targetLocationCode")}
        onChange={(e: string | null) => {
          // 이동 창고 삭제시 재고, 품목, 수량 초기화
          if (e === null) {
            form.setFieldValue("targetLotId", 0);
            form.setFieldValue("targetQuantity", "");
            form.setFieldValue("targetLocationCode", "");
          } else {
            form.setFieldValue("targetLocationCode", e);
          }
        }}
      />
      <DefectRecycle.InventoriesAutoComplete
        key={`targetLotId + ${form.values.targetItemCode} + ${form.values.targetLocationCode}`}
        label={COMMON_TEXT.LABEL.INVENTORY}
        disabled={
          !form.values.targetItemCode || !form.values.targetLocationCode
        }
        itemCode={form.values.targetItemCode}
        locationCode={form.values.targetLocationCode}
        query={{
          itemCode: form.values.targetItemCode,
        }}
        {...form.getInputProps("targetLotId")}
        onChange={(e: any) => {
          // 이동 창고 삭제시 재고, 품목, 수량 초기화
          if (e === null) {
            form.setFieldValue("targetQuantity", "");
            form.setFieldValue("targetLotId", 0);
          } else {
            form.setFieldValue("targetLotId", e);
          }
        }}
      />
      <DefectRecycle.NumberInput
        key="targetQuantity"
        label={COMMON_TEXT.LABEL.QUANTITY}
        {...form.getInputProps("targetQuantity")}
        value={Number(form.values.targetQuantity)}
      />
    </DefectRecycle.Template>
  );
};

import FormTemplate from "@/components/template/form/FormTemplate";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";
import { useSettingHandler } from "@/hooks/handler/useSettingHandler";
import { useWorkDataStore } from "@/store/work.store";
import { Group, Radio } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  ProductionPlansGet200ResponseRowsInnerWorksInnerExternalCounterSettingEnum,
  WorksWorkIdDeleteRequestExternalCounterSettingEnum,
} from "@sizlcorp/sizl-api-document/dist/models";

type SettingType =
  keyof typeof WorksWorkIdDeleteRequestExternalCounterSettingEnum;

const Setting = Object.assign(
  {},
  {
    Template: FormTemplate,
  }
);

export const SettingForm = () => {
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));

  const { work } = workData;
  const form = useForm<{ externalCounterSetting: SettingType | undefined }>({
    initialValues: {
      externalCounterSetting: undefined,
    },
  });

  const { closeModal } = useModal();
  const { onSubmit } = useSettingHandler();

  const handleOnSubmit = (value: SettingType | undefined) => {
    onSubmit({
      workId: work?.id as number,
      worksWorkIdDeleteRequest: {
        externalCounterSetting: value,
      },
    });
    closeModal({});
  };

  return (
    <Setting.Template
      onSubmit={() => handleOnSubmit(form.values.externalCounterSetting)}
    >
      <Radio.Group
        {...form.getInputProps("externalCounterSetting")}
        defaultValue={work?.externalCounterSetting}
      >
        <Group style={{ flexDirection: "column" }} mt={"md"}>
          <Radio
            disabled
            w="100%"
            value={
              ProductionPlansGet200ResponseRowsInnerWorksInnerExternalCounterSettingEnum.COUNTER_AND_AUTO_REPORT
            }
            label={COMMON_TEXT.LABEL.COUNTER_AND_AUTO_REPORT}
            description={COMMON_TEXT.DESCRIPTION.COUNTER_AND_AUTO_REPORT_DESC}
          />
          <Radio
            w="100%"
            value={
              ProductionPlansGet200ResponseRowsInnerWorksInnerExternalCounterSettingEnum.HANDWRITING
            }
            label={COMMON_TEXT.LABEL.HANDWRITING}
            description={COMMON_TEXT.DESCRIPTION.HANDWRITING_DESC}
          />
          <Radio
            w="100%"
            value={
              ProductionPlansGet200ResponseRowsInnerWorksInnerExternalCounterSettingEnum.COUNTER_AND_HANDWRITING
            }
            label={COMMON_TEXT.LABEL.COUNTER_AND_HANDWRITING}
            description={COMMON_TEXT.DESCRIPTION.COUNTER_AND_HANDWRITING_DESC}
          />
        </Group>
      </Radio.Group>
    </Setting.Template>
  );
};

// src/components/ItemInfo.tsx
import { DataBox } from "@/components/molecule/dataBox/DataBox";
import { COMMON_TEXT } from "@/constants/text";
import { useWorkDataStore } from "@/store/work.store";
import { getWorkTrackingStatus } from "@/utils/tranferWorkStatus";
import { Badge, Flex } from "@mantine/core";

export const ItemInfoBox = () => {
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { work } = workData;

  const { itemWithGrade, trackingStatus, item } = work ?? {};
  const { code, name } = item ?? {};
  const { unitText, gradeName, itemCategoryName, abstractItemCode } =
    itemWithGrade ?? {};

  return (
    <Flex wrap="wrap" bg={"#212529"} direction={"column"} p={"md"}>
      <Flex>
        <DataBox
          label={COMMON_TEXT.LABEL.CURRENT_WORK_STATUS}
          data={
            <Badge color={getWorkTrackingStatus(trackingStatus).color}>
              {getWorkTrackingStatus(trackingStatus).status}
            </Badge>
          }
        />

        <DataBox
          h={"100%"}
          label={COMMON_TEXT.LABEL.ITEM_CODE}
          data={code ?? "-"}
        />
        <DataBox
          h={"100%"}
          label={COMMON_TEXT.LABEL.ITEM_NAME}
          data={name ?? "-"}
        />
      </Flex>
      <Flex>
        <DataBox label={COMMON_TEXT.LABEL.GRADE} data={gradeName ?? "-"} />
        <DataBox
          label={COMMON_TEXT.LABEL.COLOR}
          data={itemCategoryName ?? "-"}
        />
        <DataBox
          label={COMMON_TEXT.LABEL.SHAPE}
          data={abstractItemCode ?? "-"}
        />
        {/* <DataBox label={COMMON_TEXT.LABEL.DEPTH} data={depth ?? "-"} /> */}
        <DataBox label={COMMON_TEXT.LABEL.UNIT} data={unitText ?? "-"} />
      </Flex>
    </Flex>
  );
};

import { COMMON_TEXT } from "@/constants/text";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_TableOptions,
  useMantineReactTable,
} from "mantine-react-table";
interface MantineDataTableProps<TData extends Record<string, any>>
  extends MRT_TableOptions<TData> {
  columns: MRT_ColumnDef<TData>[];
  onRowClick?: (row: TData) => void;
  data: TData[];
  maxHeight?: string | number;
}

export const MantineDataTable = <TData extends Record<string, any>>({
  columns,
  data,
  onRowClick,
  maxHeight,
  ...etcProps
}: MantineDataTableProps<TData>) => {
  const table = useMantineReactTable<TData>({
    columns,
    data: data,
    defaultColumn: {
      size: 10,
    },
    enableRowSelection: false,
    enableRowNumbers: false,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableSorting: false,
    enableTopToolbar: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableRowActions: false,
    positionActionsColumn: "last",
    enableStickyHeader: true,
    mantineTableBodyRowProps: (row) => ({
      sx: {
        color: "#FFFFFF",
        cursor: "pointer",
        "&:hover": {
          color: "#000000",
        },
        backgroundColor: "#161B21",
      },
      onClick: () => {
        const data = row.row.original;
        onRowClick && onRowClick(data);
      },
    }),
    localization: {
      noRecordsToDisplay: COMMON_TEXT.DATA_EMPTY.NO_DATA,
    },
    mantineTableHeadCellProps: {
      bg: "#161B21",
      style: {
        color: "gray",
        fontSize: "0.8rem", // 강제 적용
      },
      sx: {
        border: "1px solid white !important",
      },
    },
    mantineTableBodyCellProps: ({ cell }) => {
      const unitList = ["EA", "KG", "M", "CM", "L", "M2", "M3"];

      const cellValue = cell.getValue();
      const isUnit =
        typeof cellValue === "string" &&
        unitList.some((unit) =>
          cellValue?.toLowerCase().includes(unit.toLowerCase())
        );
      const numberValue = isUnit
        ? parseFloat(cellValue.replace(/[^\d.-]/g, ""))
        : NaN;

      const isNumber = !isNaN(numberValue);

      return {
        bg: "#161B21",
        style: {
          fontSize: "0.7rem",
          textAlign: isNumber ? "right" : "left", // 숫자일 경우 오른쪽 정렬
        },
        sx: {
          border: "1px solid white !important",
        },
      };
    },

    mantineTableProps: {
      sx: {
        backgroundColor: "#161B21",
        "tbody tr:nth-of-type(1) > td": {
          fontSize: "1.0rem",
        },
      },
    },
    mantineTableContainerProps: {
      sx: {
        maxHeight: maxHeight ?? "100%",
        overflowX: "auto", // 가로 스크롤 허용
      },
    },
    ...etcProps,
  });

  return <MantineReactTable table={table} />;
};

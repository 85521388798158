import { BaseButton } from "@/components/atom/button/BaseButton";
import { DecrementButton } from "@/components/atom/button/decrease/DecrementButton";
import { IncrementButton } from "@/components/atom/button/increase/IncrementButton";
import { FormNumberInput } from "@/components/atom/numberInput/form/FormNumberInput";
import { WorkTitle } from "@/components/atom/title/work/WorkTitle";
import { SettingForm } from "@/components/organism/form/SettingForm";
import { customFunctions } from "@/config/customFunctions";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";
import { usePerformanceHandler } from "@/hooks/handler/usePerformanceHandler";
import { useEquipmentStore } from "@/store/equipment.store";
import { useSocketStore } from "@/store/socket.store";
import { useWorkDataStore } from "@/store/work.store";
import { ActionIcon, Flex } from "@mantine/core";
import { useForm } from "@mantine/form";
import { ProductionPlansGet200ResponseRowsInnerWorksInnerExternalCounterSettingEnum } from "@sizlcorp/sizl-api-document/dist/models";
import {
  IconCircleNumber3,
  IconDeviceFloppy,
  IconSettings,
} from "@tabler/icons-react";
import { useEffect } from "react";

const WorkEndReport = Object.assign(
  {},
  {
    Title: WorkTitle,
    Button: BaseButton,
    Input: FormNumberInput,
    IncrementButton: IncrementButton,
    DecrementButton: DecrementButton,
  }
);

const COUNTER_AND_HANDWRITING =
  ProductionPlansGet200ResponseRowsInnerWorksInnerExternalCounterSettingEnum.COUNTER_AND_HANDWRITING;
const COUNTER_AND_AUTO_REPORT =
  ProductionPlansGet200ResponseRowsInnerWorksInnerExternalCounterSettingEnum.COUNTER_AND_AUTO_REPORT;

export const WorkEndReportActions = () => {
  const { getData, data } = useSocketStore((state) => ({
    getData: state.getData,
    data: state.data,
  }));

  const { handlePerformanceWithValidation, handlePerformance } =
    usePerformanceHandler();

  const { openModal } = useModal();
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { work } = workData;

  const isPacking = work?.equipment?.equipmentType === "PACKING";

  const { equipment } = useEquipmentStore((state) => ({
    equipment: state.Equipment,
  }));

  const counterData = getData(equipment?.code as string);
  const disabled = work?.externalCounterSetting === "COUNTER_AND_AUTO_REPORT";

  const form = useForm({
    initialValues: {
      end: Number(counterData?.equipmentCounter ?? "0"),
    },
  });

  // 소켓 통신으로 값 업데이트 시 상태와 폼 값 업데이트
  useEffect(() => {
    if (counterData?.equipmentCounter !== undefined) {
      form.setValues({
        end: Number(counterData.equipmentCounter),
      });
    } else {
      form.setValues({
        end: 0,
      });
    }
  }, [counterData?.equipmentCounter]);

  // 실적 저장 + 자동보고 일때 사용
  useEffect(() => {
    if (counterData?.equipmentCounter) {
      const externalCounterSetting =
        work?.externalCounterSetting ?? COUNTER_AND_HANDWRITING;

      const isCounterAndTemSaveActive =
        externalCounterSetting === COUNTER_AND_AUTO_REPORT &&
        (work?.trackingStatus === "WORKING" ||
          work?.trackingStatus === "PAUSED");

      if (isCounterAndTemSaveActive) {
        handlePerformance(
          customFunctions.ADD_ACCUMULATION_QUANTITY
            ? {
                accQuantity: counterData.equipmentCounter,
                warnInsufficiency: customFunctions.ADD_WARN_INSUFFICIENCY,
              }
            : {
                quantity: counterData.equipmentCounter,
                warnInsufficiency: customFunctions.ADD_WARN_INSUFFICIENCY,
              }
        );
      }
    }
  }, [
    work?.externalCounterSetting,
    counterData?.equipmentCounter,
    work?.trackingStatus,
  ]);

  return (
    <>
      <Flex w={"100%"} justify={"space-between"} mt={"lg"}>
        <Flex gap={10} align={"center"}>
          {!isPacking ? (
            <WorkEndReport.Title c={"#FFFFFF"} order={5}>
              {COMMON_TEXT.TITLE.PRODUCTION_INPUT}
            </WorkEndReport.Title>
          ) : (
            <WorkEndReport.Title c={"#FFFFFF"} order={5}>
              {COMMON_TEXT.TITLE.PACKING_COUNT}
            </WorkEndReport.Title>
          )}
          {!isPacking && (
            <ActionIcon>
              <IconSettings
                onClick={() =>
                  openModal(<SettingForm />, "", COMMON_TEXT.FORM.SETTING_INPUT)
                }
              />
            </ActionIcon>
          )}
        </Flex>
        <WorkEndReport.Button
          size="sm"
          onClick={() => {
            const endValue = isPacking
              ? (Number(form.values.end) * Number(work?.kgPerCount)).toString()
              : form.values.end.toString();

            handlePerformanceWithValidation(endValue);
            form.setValues({ end: 0 });
          }}
          disabled={disabled}
          type={"submit"}
          leftIcon={<IconCircleNumber3 size={20} />}
          rightIcon={<IconDeviceFloppy />}
        >
          {!isPacking
            ? COMMON_TEXT.BUTTON.PRODUCTION_SAVE
            : COMMON_TEXT.BUTTON.PACKING_SAVE}
        </WorkEndReport.Button>
      </Flex>
      <Flex gap={10} w={"100%"} mt={"sm"}>
        <WorkEndReport.DecrementButton
          size="sm"
          num={"5"}
          disabled={disabled}
          {...form.getInputProps("end")}
        />
        <WorkEndReport.DecrementButton
          size="sm"
          num={"1"}
          disabled={disabled}
          {...form.getInputProps("end")}
        />
        <WorkEndReport.Input
          w={"100%"}
          size="sm"
          disabled={disabled}
          {...form.getInputProps("end")}
        />
        <WorkEndReport.IncrementButton
          size="sm"
          num={"1"}
          disabled={disabled}
          {...form.getInputProps("end")}
        />
        <WorkEndReport.IncrementButton
          size="sm"
          num={"5"}
          disabled={disabled}
          {...form.getInputProps("end")}
        />
      </Flex>
    </>
  );
};

import { BaseButton } from "@/components/atom/button/BaseButton";
import { ButtonProps, MantineColor } from "@mantine/core";

interface HeaderActionButtonProps extends ButtonProps {
  color?: MantineColor;
  children?: string;
  onClick?: () => void;
  visible?: boolean;
}

// 명확한 컴포넌트 역할 부여
export const HeaderActionButton = (props: HeaderActionButtonProps) => {
  const { children } = props;

  return (
    <BaseButton radius="sm" {...props}>
      {children}
    </BaseButton>
  );
};

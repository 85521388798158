import { mutateTransform } from "@/api/transform/useTransformQuery";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";

import { customNotification } from "@/utils/notificationShow";
import {
  DefaultApiWmsTransformPostRequest,
  WmsTransformPostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

export const useDefectRecycleHandler = () => {
  const { closeModal } = useModal();

  const { mutate: transformMutate } = useMutation(
    (params: DefaultApiWmsTransformPostRequest) =>
      mutateTransform.transform(params).mutationFn(undefined),
    {
      onSuccess: () => {
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.TRANSFORM,
        });
        closeModal({});
      },
      onError: (error: AxiosError<Error>) => {
        if (error?.response?.data.message === undefined) return;
        customNotification.error({
          message:
            error?.response?.data.message ??
            COMMON_TEXT.NOTIFICATION.TRANSFORM_FAIL,
        });
      },
    }
  );

  const onSubmit = ({
    wmsTransformPostRequest,
  }: {
    wmsTransformPostRequest: WmsTransformPostRequest;
  }) => {
    transformMutate({ wmsTransformPostRequest });
  };
  return { onSubmit };
};

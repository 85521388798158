import { Flex, Select, SelectProps, Text } from "@mantine/core";
import { EventHandler, ReactNode, forwardRef, useState } from "react";

import {
  ItemOption,
  useItemsAutoComplete,
  useItemsCode,
} from "@/components/organism/autoComplete/item/auto-useGetItemsQuery";
import styled from "@emotion/styled";
import { IconX } from "@tabler/icons-react";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  group?: string;
  label: string;
  value: string;
  spec?: string;
}

export interface ItemAutoCompleteProps
  extends Partial<SelectProps>,
    Partial<React.RefAttributes<HTMLInputElement>> {
  value?: string | null;
  onChange?: (itemCode: string | null) => void;
  maxDropdownHeight?: number;
  width?: string;
  itemType?:
    | "PRODUCT"
    | "MATERIAL"
    | "PRODUCT,SEMI_PRODUCT"
    | "SUBSIDIARY"
    | "SEMI_PRODUCT";
}

// 입력값: value (item 모델의 code)
// 출력값: onChange (item 모델의 code)

export const ItemAutoComplete = (params: ItemAutoCompleteProps) => {
  const {
    value: itemCode,
    onChange,
    maxDropdownHeight,
    width,
    itemType,
    ...etcParams
  } = params;
  const [focused, setFocused] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const { data: options } = useItemsAutoComplete(
    focused,
    searchKeyword,
    itemType
  );
  const { data: initialOptions } = useItemsCode(!!itemCode, itemCode ?? null);

  const selectOptions = [...(options ?? []), ...(initialOptions ?? [])].reduce(
    (unique: ItemProps[], option: ItemProps) => {
      return unique.some((u) => u.value === option.value)
        ? unique
        : [...unique, option];
    },
    []
  );

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ group, label: name, value: code, spec, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <Flex direction={"column"}>
            <Text>이름 : {name}</Text>
            <Text size={"xs"} color="#666666">
              품번 : {code} , 규격 : {spec ?? "-"}
            </Text>
          </Flex>
          <Text fz="xs">{group}</Text>
        </Flex>
      </div>
    )
  );

  let selectedItem = initialOptions?.find((item) => item.value === itemCode);

  const onChangeHandler = (e: string | null) => {
    selectedItem = options?.find((item) => item.value === e);
    onChange?.(e);
  };

  return (
    <Select
      size="sm"
      withinPortal
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      clearable
      inputContainer={(children: ReactNode) => (
        <div style={{ position: "relative" }}>
          {children}
          {selectedItem?.value && (
            <div
              style={{
                position: "absolute",
                bottom: "0px",
                fontSize: "0.8em",
                color: "#888",
                left: "5px",
              }}
            >
              {"Code: " + selectedItem?.value}
            </div>
          )}
        </div>
      )}
      value={itemCode}
      itemComponent={SelectItem}
      searchValue={searchKeyword}
      data={selectOptions}
      searchable
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onChange={onChangeHandler}
      onSearchChange={setSearchKeyword}
      rightSection={ItemInfo({
        item: selectedItem as ItemOption,
        onChange: onChangeHandler,
      })}
      filter={(value, item) =>
        item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
        item?.value?.toLowerCase().includes(value.toLowerCase().trim())
      }
      nothingFound={AddNewItem({
        itemName: searchKeyword,
        onChange: onChangeHandler,
      })}
      {...etcParams}
    />
  );
};

const ItemInfo = (params: {
  item?: ItemOption;
  onChange: (itemCode: string | null) => void;
}) => {
  const { item, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(null);
  };

  return item?.value ? (
    <ItemInfoLabel>
      {/* ({item.value}) */}
      <IconX size="2rem" onClick={clearHandler} />
    </ItemInfoLabel>
  ) : null;
};

const AddNewItem = (params: {
  itemName: string;
  onChange: (itemCode: string) => void;
}) => {
  const { itemName, onChange } = params;

  const onCloseHandler: EventHandler<any> = (values) => {
    console.log(values);
  };

  const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.preventDefault();
  };

  return <></>;
};
const ItemInfoLabel = styled.div`
  font-size: 12px;
  color: #666666;
  // padding-right: 8px;
  padding-left: 0.4rem;
  display: flex;
  align-items: center;
  // right: 30px;
  position: absolute;
`;

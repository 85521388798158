import { inspectsMutate, UpdateParams } from "@/api/inspect/useInspectQuery";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";
import { useWorkDataStore } from "@/store/work.store";
import { customNotification } from "@/utils/notificationShow";
import {
  InspectionsGet200ResponseRowsInner,
  InspectionsGet200ResponseRowsInnerRecordInnerInner,
  SpcApiInspectionsInspectionIdSubmitPostRequest,
  SpcApiInspectionsPostRequest,
  SpecificationsGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation } from "@tanstack/react-query";

type onSubmitType = {
  specification: SpecificationsGet200ResponseRowsInner | undefined;
  inspection: InspectionsGet200ResponseRowsInner | undefined;
  records: InspectionsGet200ResponseRowsInnerRecordInnerInner[][];
};

export const useInspectHandler = () => {
  const { closeModal } = useModal();
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));

  const { work, lots } = workData;

  const { mutate: createMutate } = useMutation(
    (params: SpcApiInspectionsPostRequest) =>
      inspectsMutate.create(params).mutationFn(undefined),
    {
      onSuccess: () => {
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.INSPECT_SUCCESS,
        });
      },
    }
  );

  const { mutate: updateMutate, isLoading: updateLoading } = useMutation(
    (params: UpdateParams) =>
      inspectsMutate.update(params).mutationFn(undefined),
    {
      onSuccess: () => {
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.INSPECT_SUCCESS,
        });
      },
    }
  );

  const { mutate: submitMutate, isLoading: submitLoading } = useMutation(
    (params: SpcApiInspectionsInspectionIdSubmitPostRequest) =>
      inspectsMutate.submit(params).mutationFn(undefined)
  );

  const onSubmit = ({ specification, inspection, records }: onSubmitType) => {
    const hasNoPreviousInspections = !inspection;

    const handleSuccess = (response: any) => {
      submitMutate({
        inspectionId: response.data.id,
        inspectionsInspectionIdSubmitPostRequest: {
          record: records.map(
            (record: InspectionsGet200ResponseRowsInnerRecordInnerInner[]) => {
              return record.map(
                (data: InspectionsGet200ResponseRowsInnerRecordInnerInner) => {
                  return {
                    ...data,
                    isSaved: data.value === undefined ? false : true,
                    creatorUserId:
                      data.creatorUserName === localStorage.getItem("name")
                        ? (localStorage?.getItem("userId") as string)
                        : data.creatorUserId,
                  };
                }
              );
            }
          ),
        },
      });
      closeModal({});
    };

    if (hasNoPreviousInspections) {
      createMutate(
        {
          inspectionsGetRequest: {
            itemCode: work?.item?.code as string,
            specificationId: specification?.id!,
            productionPlanId: work?.productionPlanId,
            equipmentCode: work?.equipmentCode,
            lotId: lots?.id,
          },
        },
        {
          onSuccess: handleSuccess,
        }
      );
    } else {
      updateMutate(
        {
          inspectionId: inspection.id as number,
          inspectionsGetRequest: {
            specificationId: specification?.id!,
            itemCode: work?.item?.code as string,
            specificationData: inspection?.specificationData,
            record: records,
            equipmentCode: work?.equipmentCode,
          },
        },
        {
          onSuccess: handleSuccess,
        }
      );
    }
  };

  return {
    onSubmit,
  };
};
